import React, { useEffect, useCallback } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Menu, Image, Segment, Dropdown } from "semantic-ui-react";

import logo from "app/assets/images/logo.png";

const Topbar = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        marginBottom: "2%",
        borderBottomWidth: 1.5,
        borderBottomColor: "#014886",
        borderBottomStyle: "solid",
      }}
    >
      <div style={{ width: "40%" }}>
        <img src={logo} style={{ width: "100%" }} />
      </div>
    </div>
  );
};

export default Topbar;
