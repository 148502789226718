import React, { useState, useEffect } from "react";

import { useParams } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";

import { projects } from "app/data";

const Project = (props) => {
  const { id } = useParams();

  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const project = projects.find((p) => p.id === id);

    if (!project) {
      return;
    }

    setPhotos(project.photos);
  }, [id]);

  return (
    <div
      style={{
        flex: 1,
        paddingTop: 20,
        paddingBottom: 20,
        minHeight: 400,
        fontFamily: "Roboto",
      }}
    >
      <Carousel>
        {photos.map((photo) => {
          return (
            <div>
              <img src={photo.url} />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Project;
