import React from "react";

import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";

import Routes from "app/routes";
import NavBar from "app/pages/nav_bar";

import { AppContainer } from "app/styles";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faAppleAlt,
  faIceCream,
  faCookie,
} from "@fortawesome/free-solid-svg-icons";

import "react-toastify/dist/ReactToastify.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import "app/assets/styles/index.css";

library.add(fab, faAppleAlt, faIceCream, faCookie);

export default function App() {
  return (
    <AppContainer>
      <Router>
        <div
          style={{
            maxWidth: 1200,
            margin: "auto auto",
          }}
        >
          <NavBar />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              height: "100%",
            }}
          >
            <Routes />
          </div>
        </div>

        <ToastContainer />
      </Router>
    </AppContainer>
  );
}
