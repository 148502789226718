import projectPhotoOne1 from "app/assets/projects/one/photo_1.png";
import projectPhotoOne2 from "app/assets/projects/one/photo_2.png";

import projectPhotoTwo1 from "app/assets/projects/two/photo_1.jpg";
import projectPhotoTwo2 from "app/assets/projects/two/photo_2.jpg";
import projectPhotoTwo3 from "app/assets/projects/two/photo_3.jpg";
import projectPhotoTwo4 from "app/assets/projects/two/photo_4.jpg";
import projectPhotoTwo7 from "app/assets/projects/two/photo_7.jpg";
import projectPhotoTwo8 from "app/assets/projects/two/photo_8.jpg";
import projectPhotoTwo9 from "app/assets/projects/two/photo_9.jpg";

import projectPhotoThree1 from "app/assets/projects/three/photo_1.png";
import projectPhotoThree2 from "app/assets/projects/three/photo_2.png";
import projectPhotoThree3 from "app/assets/projects/three/photo_3.png";
import projectPhotoThree4 from "app/assets/projects/three/photo_4.png";

import projectPhotoFour1 from "app/assets/projects/four/photo_1.png";
import projectPhotoFour2 from "app/assets/projects/four/photo_2.png";
import projectPhotoFour3 from "app/assets/projects/four/photo_3.png";
import projectPhotoFour4 from "app/assets/projects/four/photo_4.png";

import projectPhotoFive1 from "app/assets/projects/five/photo_1.png";
import projectPhotoFive2 from "app/assets/projects/five/photo_2.png";
import projectPhotoFive3 from "app/assets/projects/five/photo_3.png";
import projectPhotoFive4 from "app/assets/projects/five/photo_4.png";
import projectPhotoFive5 from "app/assets/projects/five/photo_5.png";
import projectPhotoFive6 from "app/assets/projects/five/photo_6.png";
import projectPhotoFive7 from "app/assets/projects/five/photo_7.png";
import projectPhotoFive8 from "app/assets/projects/five/photo_8.png";
import projectPhotoFive9 from "app/assets/projects/five/photo_9.png";
import projectPhotoFive10 from "app/assets/projects/five/photo_10.png";
import projectPhotoFive11 from "app/assets/projects/five/photo_11.png";
import projectPhotoFive12 from "app/assets/projects/five/photo_12.png";

import projectPhotoSix1 from "app/assets/projects/six/photo_1.png";
import projectPhotoSix2 from "app/assets/projects/six/photo_2.png";
import projectPhotoSix3 from "app/assets/projects/six/photo_3.png";
import projectPhotoSix4 from "app/assets/projects/six/photo_4.png";
import projectPhotoSix5 from "app/assets/projects/six/photo_5.png";
import projectPhotoSix6 from "app/assets/projects/six/photo_6.png";
import projectPhotoSix7 from "app/assets/projects/six/photo_7.png";

import projectPhotoSeven1 from "app/assets/projects/seven/photo_1.png";
import projectPhotoSeven2 from "app/assets/projects/seven/photo_2.png";
import projectPhotoSeven3 from "app/assets/projects/seven/photo_3.png";
import projectPhotoSeven4 from "app/assets/projects/seven/photo_4.png";
import projectPhotoSeven5 from "app/assets/projects/seven/photo_5.png";
import projectPhotoSeven6 from "app/assets/projects/seven/photo_6.png";
import projectPhotoSeven7 from "app/assets/projects/seven/photo_7.png";

import projectPhotoEight1 from "app/assets/projects/eight/photo_1.png";
import projectPhotoEight2 from "app/assets/projects/eight/photo_2.png";
import projectPhotoEight3 from "app/assets/projects/eight/photo_3.png";
import projectPhotoEight4 from "app/assets/projects/eight/photo_4.png";
import projectPhotoEight5 from "app/assets/projects/eight/photo_5.png";
import projectPhotoEight6 from "app/assets/projects/eight/photo_6.png";
import projectPhotoEight7 from "app/assets/projects/eight/photo_7.png";

export const projects = [
  {
    id: "project-1",
    image: projectPhotoOne1,
    title: "Чистка отопления.",
    photos: [
      {
        url: projectPhotoOne1,
      },
      {
        url: projectPhotoOne2,
      },
    ],
  },

  {
    id: "project-2",
    image: projectPhotoTwo1,
    title: "Ремонт газовых двухконтурных и одноконтурных котлов.",
    photos: [
      {
        url: projectPhotoTwo1,
      },
      {
        url: projectPhotoTwo2,
      },
      {
        url: projectPhotoTwo3,
      },
      {
        url: projectPhotoTwo4,
      },
      {
        url: projectPhotoTwo7,
      },
      {
        url: projectPhotoTwo8,
      },
      {
        url: projectPhotoTwo9,
      },
    ],
  },

  {
    id: "project-3",
    image: projectPhotoThree1,
    title: "Чистка котлов",
    photos: [
      {
        url: projectPhotoThree1,
      },
      {
        url: projectPhotoThree2,
      },
      {
        url: projectPhotoThree3,
      },
      {
        url: projectPhotoThree4,
      },
    ],
  },

  {
    id: "project-4",
    image: projectPhotoFour1,
    title: "Профилактика котлов",
    photos: [
      {
        url: projectPhotoFour1,
      },
      {
        url: projectPhotoFour2,
      },
      {
        url: projectPhotoFour3,
      },
      {
        url: projectPhotoFour4,
      },
    ],
  },

  {
    id: "project-5",
    image: projectPhotoFive1,
    title: "Ремонт котлов",
    photos: [
      {
        url: projectPhotoFive1,
      },
      {
        url: projectPhotoFive2,
      },
      {
        url: projectPhotoFive3,
      },
      {
        url: projectPhotoFive4,
      },
      {
        url: projectPhotoFive5,
      },
      {
        url: projectPhotoFive6,
      },
      {
        url: projectPhotoFive7,
      },
      {
        url: projectPhotoFive8,
      },
      {
        url: projectPhotoFive9,
      },
      {
        url: projectPhotoFive10,
      },
      {
        url: projectPhotoFive11,
      },
      {
        url: projectPhotoFive12,
      },
    ],
  },

  {
    id: "project-6",
    image: projectPhotoSix1,
    title: "Профилактика котлов",
    photos: [
      {
        url: projectPhotoSix1,
      },
      {
        url: projectPhotoSix2,
      },
      {
        url: projectPhotoSix3,
      },
      {
        url: projectPhotoSix4,
      },
      {
        url: projectPhotoSix5,
      },
      {
        url: projectPhotoSix6,
      },
      {
        url: projectPhotoSix7,
      },
    ],
  },

  {
    id: "project-7",
    image: projectPhotoSeven1,
    title: "Ремонт напольных котлов",
    photos: [
      {
        url: projectPhotoSeven1,
      },
      {
        url: projectPhotoSeven2,
      },
      {
        url: projectPhotoSeven3,
      },
      {
        url: projectPhotoSeven4,
      },
      {
        url: projectPhotoSeven5,
      },
      {
        url: projectPhotoSeven6,
      },
      {
        url: projectPhotoSeven7,
      },
    ],
  },

  {
    id: "project-8",
    image: projectPhotoEight1,
    title: "Ремонт двухконтур газовых котлов",
    photos: [
      {
        url: projectPhotoEight1,
      },
      {
        url: projectPhotoEight2,
      },
      {
        url: projectPhotoEight3,
      },
      {
        url: projectPhotoEight4,
      },
      {
        url: projectPhotoEight5,
      },
      {
        url: projectPhotoEight6,
      },
      {
        url: projectPhotoEight7,
      },
    ],
  },
];
