import React, { useState, useEffect } from "react";

import Card from "./Card";

import mainImage from "app/assets/images/main.jpg";
import companyNamesImage from "app/assets/images/company-names.png";

import whatsupIcon from "app/assets/icons/whatsapp.png";
import telegramIcon from "app/assets/icons/telegram.png";

import { projects } from "app/data";

const Order = () => {
  const [open, setOpen] = useState(false);
  const [photos, setPhotos] = useState([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [iconWidth, setIconWidth] = useState(48);
  const [iconHeight, setIconHeight] = useState(48);

  // Function to update the window width
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Use useEffect to add the event listener when the component mounts
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures this runs once when the component mounts

  useEffect(() => {
    setIconWidth(windowWidth / 30);
    setIconHeight(windowWidth / 30);
  }, [windowWidth]);

  return (
    <div
      style={{
        flex: 1,
        paddingTop: 20,
        paddingBottom: 20,
        minHeight: 400,
        fontFamily: "Roboto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 12px",
          marginBottom: "4%",
        }}
      >
        <div
          style={{
            width: "48%",
            lineHeight: 1,
            color: "#014886",
            fontSize: "max(2vw, 14px)",
            fontFamily: "Roboto",
          }}
        >
          Выполняем быстро, качественно и надежно.
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            width: "50%",
            alignItems: "flex-end",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              color: "#014886",
              marginBottom: "min(8%, 4%)",
              fontSize: "max(2vw, 12px)",
            }}
          >
            Возникли вопросы? Звоните!
          </div>
          <div
            style={{
              color: "#DA0000",
              fontSize: "max(2.5vw, 18px)",
              fontWeight: "bold",
            }}
          >
            <a href="tel:+998935785148" style={{ color: "#DA0000" }}>
              +998 93 578 51 48
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          // height: 732,
          maxHeight: 500,
          marginBottom: "10%",
          // backgroundSize: "cover",
          // backgroundImage: `url(${mainImage})`,
          position: "relative",
          borderRadius: 4,
          overflow: "hidden",
        }}
      >
        <img src={mainImage} style={{ width: "100%" }} />
        <div
          style={{
            display: "flex",
            height: "20%",
            justifyContent: "space-between",
            backgroundColor: "rgba(33,33,33,0.7)",
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            alignItems: "center",
            padding: "6%",
            position: "absolute",
            bottom: 0,
            width: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              padding: "3% 6%",
              borderRadius: 4,
              justifyContent: "center",
              display: "flex",
              backgroundColor: "#F73C3D",
              fontSize: "max(1.5vw, 12px)",
              height: "80%",
              color: "white",
            }}
          >
            <img
              src={whatsupIcon}
              style={{ marginRight: 8, width: iconWidth, height: iconHeight }}
            />
            <a href="tel:+998935785148" style={{ color: "white" }}>
              +998 93 578 51 48
            </a>
          </div>

          <div
            style={{
              alignItems: "center",
              padding: "3% 6%",
              borderRadius: 4,
              justifyContent: "center",
              display: "flex",
              backgroundColor: "#F73C3D",
              fontSize: "max(1.5vw, 12px)",
              height: "80%",
              color: "white",
            }}
          >
            <img
              src={telegramIcon}
              style={{ marginRight: 8, width: iconWidth, height: iconHeight }}
            />
            <a href="tel:+998935785148" style={{ color: "white" }}>
              +998 93 578 51 48
            </a>
          </div>
        </div>
      </div>

      <div
        style={{
          borderBottomColor: "#014886",
          borderBottomSize: 1,
          borderBottomStyle: "solid",
          paddingBottom: "4%",
          marginBottom: "8%",
        }}
      >
        <div
          style={{
            marginBottom: "6%",
            color: "#0079E2",
            fontSize: "max(3vw, 32px)",
            fontWeight: "medium",
            fontFamily: "Roboto",
          }}
        >
          Наши Услуги
        </div>

        <ul
          style={{
            lineHeight: 1.3,
            fontFamily: "Inter",
            fontSize: "max(2.5vw, 24px)",
            fontWeight: "normal",
          }}
        >
          <li>
            Ремонтируем, чистим и делаем профилактику газовых и кондиционних
            котлов.
          </li>
          <li>Любые котлы: одноконтурные, двухконтурный и напольные котлы.</li>
          <li>А также ремонт газовых и электрических котлов. </li>
          <li>Монтаж и демонтаж отопительного оборудования.</li>
          <li>Диагностика и запуск отопительного оборудования. </li>
          <li>Переделка отопления. </li>
          <li>Диагностика и консультация.</li>
        </ul>
      </div>

      <div
        style={{
          borderBottomColor: "#014886",
          borderBottomSize: 1,
          borderBottomStyle: "solid",
          paddingBottom: "4%",
          marginBottom: "8%",
        }}
      >
        <div
          style={{
            color: "#0079E2",
            fontWeight: "medium",
            fontFamily: "Roboto",
            lineHeight: 1,
            marginBottom: "6%",
            color: "#0079E2",
            fontSize: "max(3vw, 32px)",
          }}
        >
          Сотрудничаем с Фирмами
        </div>
        <div>
          <img src={companyNamesImage} style={{ width: "100%" }} />
        </div>
      </div>

      <div
        style={{
          paddingBottom: "4%",
          marginBottom: "8%",
        }}
      >
        <div
          style={{
            marginBottom: "6%",
            color: "#0079E2",
            fontSize: "max(3vw, 32px)",
            fontWeight: "medium",
            fontFamily: "Roboto",
          }}
        >
          Наши Работы
        </div>

        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {projects.map((project) => (
            <Card
              key={project.id}
              project={project}
              openModal={(photos) => {
                setPhotos(photos);
                setOpen(true);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Order;
