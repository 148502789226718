import React from "react";

import { Route, Routes } from "react-router-dom";

import Main from "app/pages/main";
import Project from "app/pages/main/project";

import NotFound from "app/pages/not_found";

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/project/:id" element={<Project />} />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
