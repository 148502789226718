import React from "react";

import { Container } from "semantic-ui-react";

import TopBar from "./TopBar";

export default function NavBar(props) {
  return (
    <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
      <TopBar />
    </div>
  );
}
