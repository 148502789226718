import React from "react";

import Order from "app/pages/main/order";

export default function Main() {
  return (
    <div style={{ paddingLeft: "2%", paddingRight: "2%" }}>
      <Order />
    </div>
  );
}
