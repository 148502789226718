import React, { useState, useEffect, createRef } from "react";

import { useNavigate } from "react-router-dom";

const Card = ({ project, openModal }) => {
  const navigate = useNavigate();

  const { id, image, title, photos } = project;

  return (
    <div
      style={{
        width: "23.3%",
        overflow: "hidden",
        aspectRatio: "1 / 1",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderRadius: 4,
        marginRight: "1%",
        marginBottom: "2%",
        border: "4px solid #C3CFD9",
        flexDirection: "column",
        cursor: "pointer",
      }}
      onClick={() => {
        navigate(`/project/${id}`);
      }}
    >
      <div
        style={{
          height: "80%",
          width: "100%",
          overflow: "hidden",
          backgroundSize: "cover",
          backgroundImage: `url(${image})`,
        }}
      ></div>
      <div
        style={{
          padding: "4%",
          fontSize: "max(1.2vw, 14px)",
          fontFamily: "Roboto",
          lineHeight: 1,
          fontWeight: "600",
        }}
      >
        {title}
      </div>
    </div>
  );
};

export default Card;
